h1,h2 {
    letter-spacing: 1px;
}
#wrapper {
  width: 100%;
}
#page-wrapper {
    padding: 0 15px 20px 15px;
  min-height: 600px;
  background-color: white;
    margin: 0;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    padding: 0 30px 20px 30px;
    border-left: 1px solid #e7e7e7;
  }
  .navbar-header {
    margin-left: 15px;
  }
  .navbar-nav > li {
    float: none;
  }
}
.powered-by {
    text-align: center;
    color: #ccc;
    padding: 10px 0;
}
.navbar-static-top {
    margin-bottom: 0;
}
.navbar-top-links {
    margin-right: 0;
}
.navbar-top-links li {
  display: inline-block;
}
@media (min-width: 768px) {
    .navbar-top-links li:last-child {
        margin-right: 15px;
    }
}
.navbar-top-links li a {
  padding: 15px;
  min-height: 50px;
}
.navbar-top-links .dropdown-menu li {
  display: block;
}
.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}
@media (max-width: 767px) {
    .navbar-default li {
        width: 100%;
    }
}
/** Breadcrumbs **/
.breadcrumb {
    font-size: 0.8em;
    margin: 0;
    padding: 8px 0;
}
.breadcrumb li {
    max-width: 100%;
}
/** Datatables should use fontawesome sort icons **/
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}
table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  float: right;
  font-family: fontawesome;
}
table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}
table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, 0.5);
}
/** Tag editor datatable **/
.dt-length { float: left; }
.dt-buttons { float: left; margin-left: 10px;margin-bottom:10px;}
.dt-search { float: right; }
.dt-paging {text-align: right;}
.dt-info { padding-top: 8px; }
@media (min-width: 768px) {
    .jumbotron {
        border-radius: 6px;
    }
}
.fa-jumbo {
  font-size: 126px;
  margin-top: 20px;
}
@media (max-width: 500px) {
    .fa-jumbo {font-size:90px;}
}
.tab-content {
    border: 1px solid #ddd; border-top:0;
    padding:10px;position:relative;border-radius:0 0 5px 5px
}
.messages > div:first-child {
  margin-top:30px;
}
.messages > div:last-child {
    margin-bottom:0;
}
.page-header {
    margin: 10px 0;
}
a[aria-expanded=true] .fa-chevron-up {
   display: none;
}
a[aria-expanded=false] .fa-chevron-down {
   display: none;
}
.btn-sidebar {
  width:100%;
  margin-bottom: 10px;
}
.btn-sidebar-nomgn {
    margin-bottom: 0;
}
#hts-move-selection button {
    width:25%;
    max-width: none;
    margin-bottom: 10px;
}
.dropdown-menu .pull-right.fa {
    margin-right: -15px;
}
@media (min-width:768px) {
    .navbar-top-links .dropdown:hover .dropdown-menu {
        display: block;
    }
    .navbar-top-links .dropdown:hover {
        background-color: #337ab7;
    }
}
.ellipsis {
    overflow:hidden;
    text-overflow: ellipsis;
}
  #selectable-wells .ui-selecting { background: #FECA40; }
  #selectable-wells .ui-selected { background: #F39814; }
  .welllist { list-style-type: none;
      margin: 0;
      padding: 0; }
  #well-all {-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;}

  /*#hts-plate-panel {min-width:500px;}*/
    #hts-plate-dropdown {
        margin-bottom: 10px;
    }
  #hts-plate-dropdown, #hts-dataset-nav {
      display: inline-block;width:100%;}
  #hts-dataset-nav {
      margin-left: 5px;
  }
  #hts-dataset-nav button {
      width: 33.33%;
  }
  #hts-download-plate {width:100%;}
  #hts-apply-template-multiple {
      width: 100%;
      margin-top: 10px;
  }
  #hts-apply-template-multiple .bootstrap-select {
      float: left;
  }
  #hts-select-plate {width:100%;}
  #selectable-well-rows {width:4%; float: left; clear:left; }
  .welllist li { margin: 0; padding: 0 0 4% 0; float: left; width: 4%;
      height: 0; cursor: default; text-align: center; font-size:0.6em;
      border-radius: 50%; }
  /* Plate sizes */
    .plate1536 {
        font-size: 60%;
    }
    .plate1536 .hts-well, .plate1536 .col {
        width: 2.04081%;
        padding-bottom: 2.04081%;
    }
    .plate1536 #selectable-well-rows {
        width: 2.04081%;
    }
    .plate384 .hts-well, .plate384 .col {
        width: 4%;
        padding-bottom: 4%;
    }
    .plate384 #selectable-well-rows {
        width: 4%;
    }
    .plate96 {
        font-size: 1.5em;
    }
    .plate96 .hts-well, .plate96 .col {
        width: 7.69230%;
        padding-bottom: 7.69230%;
    }
    .plate96 #selectable-well-rows {
        width: 7.69230%;
    }
  /* End plate sizes */
  @media (min-width: 600px) {
      .welllist li {font-size:1em;}
  }
  @media (min-width:768px) {
      #hts-plate-dropdown, #hts-dataset-nav, #hts-dataset-nav button {
          width: auto;
          margin-bottom:0;
      }
  }
  @media (min-width: 992px) {
      .welllist li {font-size:125%}
  }
  @media (min-width: 1200px) {
      .welllist li {font-size:150%}
      #hts-apply-template-multiple {
          width: auto;
          margin-top: 0;
      }
  }
  #selectable-well-rows li {width: 100%; height:0; padding-bottom:100%;
      border:1px solid transparent; }

 .ui-selectable-helper { display:none }

  .scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
 }
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
}
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}
.upload-drop-zone.drop {
    color: #222;
    border-color: #222;
}

.tt-query {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.tt-hint {
    color: #999;
}
.tt-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding: 4px 0;
}
.tt-suggestion {
    line-height: 24px;
    padding: 3px 20px;
}
.tt-suggestion:hover {
    background-color: #0097cf;
    color: #fff;
    cursor: pointer;
}
.tt-suggestion.tt-cursor {
    background-color: #0097cf;
    color: #fff;
}
.tt-suggestion p {
    margin: 0;
}
.hts-well {
    border: 1px solid #c5c5c5;
    background: #f6f6f6;
}
.hts-overview .hts-cell-line {
    background-color: #a1aec7;
}
.hts-overview .hts-drug {
    background-color: #ebccd1;
}
.hts-overview .hts-dose {
    background-color: #f7ecb5;
}
.hts-overview .hts-cell-line.hts-drug {
    background-color: #a6e1ec;
    background: repeating-linear-gradient(
  45deg,
  #a1aec7,
  #a1aec7 6px,
  #ebccd1 6px,
  #ebccd1 12px
    );
}
.hts-overview .hts-cell-line.hts-dose {
    background-color: #adadad;
    background: repeating-linear-gradient(
  45deg,
  #a1aec7,
  #a1aec7 6px,
  #f7ecb5 6px,
  #f7ecb5 12px
    );
}
.hts-overview .hts-drug.hts-dose {
    background-color: #f0e8ff;
    background: repeating-linear-gradient(
  45deg,
  #ebccd1,
  #ebccd1 6px,
  #f7ecb5 6px,
  #f7ecb5 12px
    );
}
.hts-overview .hts-cell-line.hts-drug.hts-dose {
    background: #000;
}
.welllist.well-legend {
    display: block;
    clear: left;
    line-height: 1em;
}
.welllist.well-legend li {
    display: block;
    width: 1.5em;
    padding-bottom: 1.5em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
}
#legend-overview,#legend-cell-lines,#legend-drugs,#legend-doses,#legend-dip {
    display:none;
}
.hts-overview #legend-overview {
    display:block;
}
.hts-celllines #legend-cell-lines {
    display:block;
}
.hts-drugs #legend-drugs {
    display:block;
}
.hts-doses #legend-doses {
    display:block;
}
.hts-dip #legend-dip {
    display:block;
}
.hts-celllines .hts-cell-line-0,.hts-drugs .hts-drug-0,.hts-doses .hts-dose-0 {
    background-color: #FF5005; }
.hts-celllines .hts-cell-line-1,.hts-drugs .hts-drug-1,.hts-doses .hts-dose-1 {
    background-color: #F0A3FF; }
.hts-celllines .hts-cell-line-2,.hts-drugs .hts-drug-2,.hts-doses .hts-dose-2 {
    background-color: #0075DC; }
.hts-celllines .hts-cell-line-3,.hts-drugs .hts-drug-3,.hts-doses .hts-dose-3 {
    background-color: #993F00; }
.hts-celllines .hts-cell-line-4,.hts-drugs .hts-drug-4,.hts-doses .hts-dose-4 {
    background-color: #4C005C; }
.hts-celllines .hts-cell-line-5,.hts-drugs .hts-drug-5,.hts-doses .hts-dose-5 {
    background-color: #191919; }
.hts-celllines .hts-cell-line-6,.hts-drugs .hts-drug-6,.hts-doses .hts-dose-6 {
    background-color: #005C31; }
.hts-celllines .hts-cell-line-7,.hts-drugs .hts-drug-7,.hts-doses .hts-dose-7 {
    background-color: #2BCE48; }
.hts-celllines .hts-cell-line-8,.hts-drugs .hts-drug-8,.hts-doses .hts-dose-8 {
    background-color: #FFCC99; }
.hts-celllines .hts-cell-line-9,.hts-drugs .hts-drug-9,.hts-doses .hts-dose-9 {
    background-color: #808080; }
.hts-celllines .hts-cell-line-10,.hts-drugs .hts-drug-10,.hts-doses .hts-dose-10 {
    background-color: #94FFB5; }
.hts-celllines .hts-cell-line-11,.hts-drugs .hts-drug-11,.hts-doses .hts-dose-11 {
    background-color: #8F7C00; }
.hts-celllines .hts-cell-line-12,.hts-drugs .hts-drug-12,.hts-doses .hts-dose-12 {
    background-color: #9DCC00; }
.hts-celllines .hts-cell-line-13,.hts-drugs .hts-drug-13,.hts-doses .hts-dose-13 {
    background-color: #C20088; }
.hts-celllines .hts-cell-line-14,.hts-drugs .hts-drug-14,.hts-doses .hts-dose-14 {
    background-color: #003380; }
.hts-celllines .hts-cell-line-15,.hts-drugs .hts-drug-15,.hts-doses .hts-dose-15 {
    background-color: #FFA405; }
.hts-celllines .hts-cell-line-16,.hts-drugs .hts-drug-16,.hts-doses .hts-dose-16 {
    background-color: #FFA8BB; }
.hts-celllines .hts-cell-line-17,.hts-drugs .hts-drug-17,.hts-doses .hts-dose-17 {
    background-color: #426600; }
.hts-celllines .hts-cell-line-18,.hts-drugs .hts-drug-18,.hts-doses .hts-dose-18 {
    background-color: #FF0010; }
.hts-celllines .hts-cell-line-19,.hts-drugs .hts-drug-19,.hts-doses .hts-dose-19 {
    background-color: #5EF1F2; }
.hts-celllines .hts-cell-line-20,.hts-drugs .hts-drug-20,.hts-doses .hts-dose-20 {
    background-color: #00998F; }
.hts-celllines .hts-cell-line-21,.hts-drugs .hts-drug-21,.hts-doses .hts-dose-21 {
    background-color: #E0FF66; }
.hts-celllines .hts-cell-line-22,.hts-drugs .hts-drug-22,.hts-doses .hts-dose-22 {
    background-color: #740AFF; }
.hts-celllines .hts-cell-line-23,.hts-drugs .hts-drug-23,.hts-doses .hts-dose-23 {
    background-color: #990000; }
.hts-celllines .hts-cell-line-24,.hts-drugs .hts-drug-24,.hts-doses .hts-dose-24 {
    background-color: #FFFF80; }
.twitter-typeahead {
    width: 100%
}
.hts-drug-entry {
    margin-top: 15px;
    margin-bottom: 15px;
}
.hts-dose-select {
    min-width:0;
    width:100%
}
#hts-add-drug {
    margin-top:5px;
    margin-bottom:5px;
}
#hts-remove-drug {
    display: none;
    margin-bottom:5px;
}
#hts-apply-template-multiple select, #apply-template-multiple-btn, .hts-drug-num {
    display: none;
}
#hts-well-table-view, #hts-dip-display {
    display: none;
}
#auto-dilution-div {
    display: none;
    margin-bottom: 10px;
}
#hts-well-table-view table { width:100%; }
#hts-apply-template-multiple { display: inline-block; }
.modal-header {
    font-size: 20pt;
}
/** Loading indicators **/
.loading-overlay {
    display: none;
    position: absolute;
    background: rgba(2,37,89,0.25);
    z-index: 5000;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.modal {
    z-index: 6000;
}
.modal-backdrop {
    z-index: 5999;
}
#loading-modal {
    padding-top:15%;
    overflow-y:visible;
    z-index: 10000;
}
#loading-modal .loading-overlay {
    display: block;
    background: none;
}
#loading-modal .modal-dialog {
    height: 200px;
    width: 200px;
}
#loading-modal .modal-content {
    height: 200px;
    width: 200px;
}
.loading-overlay .bolt {
    position: relative;
    top: 50%;
    margin-top: -50px;
    height: 50px;
}
.loading-overlay .fa-bolt {
    display: inline-block;
    position: relative;
    font-size: 50px;
    top: -55px;
    color: #fff;
    width: 100%;
    text-align: center;
}
.loading-overlay .fa-bolt:before {
    -webkit-animation: lightning 2.4s linear infinite;
            animation: lightning 2.4s linear infinite;
}
.sk-folding-cube {
  margin: -30px auto 0 auto;
  top: 50%;
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #022559;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.24s;
          animation-delay: 0.24s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.48s;
          animation-delay: 0.48s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.72s;
          animation-delay: 0.72s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 20%, 80% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 20%, 80% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes lightning {
    0%, 55%, 75%, 100% { opacity: 0; }
    60% { opacity: 1; }
}
@keyframes lightning {
    0%, 55%, 75%, 100% { opacity: 0; }
    60% { opacity: 1; }
}

/** End loading indicators **/

/** Plotting interface **/
.plot-panel {
    border: 1px solid transparent;
    display: none;
}
.panel-title {
    font-weight: 500;
}
.plot-panel .panel-body {
    position:relative;
    height:450px;
    /*overflow:visible;*/
}
.panel-placeholder {
    border: 1px solid #999;
    border-radius: 3px;
    background: repeating-linear-gradient(
       45deg, #ccc, #ccc 10px,
       #bbb 10px, #bbb 20px
    );
    float: left;
}
#plot-toolbar {
    margin-bottom: 10px;
}
#plot-toolbar .dropdown-menu {
    z-index: 2000;
}
.plot-panel .dropdown-menu {
    z-index: 1002;
}
.hts-change-data {
    display: none;
    position: absolute;
    top: 0;
    left: -1px;
    /*background: rgba(255, 255, 255, 0.9);*/
    background: #dee4ed;
    margin: 0;
    padding: 10px;
    width: 100%;
    max-width: 860px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-top: none;
    border-radius: 0 4px 4px 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
#hts-num-cols-lg, #hts-num-cols-md {
    float:left;
    margin-right:10px;
}
@media (max-width: 1199px) {
    #hts-num-cols-md { display: block; }
    #hts-num-cols-lg { display: none; }
}
@media (max-width: 991px) {
    #hts-num-cols-md { display: none;}
}
@media (min-width: 1200px) {
    #hts-num-cols-md { display: none; }
    #hts-num-cols-lg { display: block; }
}
.panel-close-btn {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: 6px 12px;
    border-radius: 4px;
}
/*.sortable-panels .btn-group {*/
    /*margin-left: 10px;*/
/*}*/
.hts-change-data .btn-group:not(.btn-group-inline) {
    width: 100%;
    height: 34px;
    margin-left: 0;
    display: block;
}
.hts-change-data .btn {
    overflow-x: hidden;
}
div.hts-change-cell-line .bs-actionsbox,div.hts-change-drug .bs-actionsbox {
    display: none;
}
.hts-aggregate {
    margin-bottom: 0;
}
.btn-group-1 label { width: 100%; }
.btn-group-2 label { width: 50%; }
.btn-group-3 label { width: 33.333%; }
.btn-group-4 label { width: 25%; }
.btn-group-5 label { width: 20%; }
.hts-change-data-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 1350px) {
  .col-lg-4.plot-panel .hidden-xs {
      display: none;
  }
}
@media (max-width: 1800px) {
  .col-lg-3.plot-panel .hidden-xs {
      display: none;
  }
  .col-lg-3 .hts-change-data .col-sm-6 {
      width: 100%;
  }
  .col-lg-3 .btn-group-responsive .pull-left,
  .col-lg-3 .btn-group-responsive .pull-right {
      float: none;
  }
  .col-lg-3 .btn-group-responsive .btn {
      display: block;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
  }
  .col-lg-3 .btn-group-responsive:not(.btn-group) .btn {
      margin-bottom: 10px;
  }
  .col-lg-3 .btn-group-responsive .btn:last-child { margin-bottom: 0; }

  .col-lg-3 .btn-group-responsive.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
  .col-lg-3 .btn-group-responsive.btn-group>.btn:last-child:not(:first-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 0;
    margin-bottom: 15px;
  }

  .col-lg-3 .btn-group-responsive.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
  }
  .col-lg-3 .btn-group-responsive.btn-group>.btn:last-child:not(:first-child) {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .col-lg-3 .btn-group-responsive.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
      border-top:0;
      border-bottom:0;
  }

}
@media (max-width: 767px) {
  .btn-group-responsive .pull-left, .btn-group-responsive .pull-right {
      float: none;
  }
  .btn-group-responsive .btn {
      display: block;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
  }
  .btn-group-responsive:not(.btn-group) .btn {
      margin-bottom: 10px;
  }
  .btn-group-responsive .btn:last-child { margin-bottom: 0; }

  .btn-group-responsive.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
  .btn-group-responsive.btn-group>.btn:last-child:not(:first-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 0;
    margin-bottom: 15px;
  }

  .btn-group-responsive.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
  }
  .btn-group-responsive.btn-group>.btn:last-child:not(:first-child) {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .btn-group-responsive.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
      border-top:0;
      border-bottom:0;
  }
}
  .plot-header-ctrl {
    display: inline-flex;
    vertical-align: middle;
    line-height: 32px;
    margin-top:10px;
    width:100%;
  }
  .plot-header-ctrl label {
    margin-right: 10px;
  }
  .plot-header-ctrl button {
    width: 100%;
    border-radius: 5px;
    height: 42px;
  }
  #hts-back-btn {
    margin-top:10px;
    width: 100%;
  }
  #plot-dataset-sel {
    width: 100%;
  }
@media (min-width:768px) {
  .plot-header-ctrl {
    width: auto;
    padding-right:10px;
  }
  .plot-header-ctrl button {
    width: auto;
    height: inherit;
  }
  #plot-dataset-sel {
    width: auto;
  }
  #hts-back-btn {
    width: auto;
  }
  .hts-plot-type .btn {
     white-space:normal;
     display: table-cell;
     float: none;
  }
}
.hts-plot-type.btn-group:not(.btn-group-inline) {
    height: auto;
}
.sortable-panels .panel-body {
    padding: 0;
}
.sortable-panels .panel {
    margin-bottom: 0;
}
.panel-container {
    padding: 0;
}
.plotly-graph-div {
    overflow-x: hidden;
}
.show-plot-delayed {
    text-align: center;
    background-color: #ffe;
    height: 100%;
    padding-top: 40%;
    display: none;
}
/* Tagging interface */
h4.tag-header {
    margin-top: 8px;
    margin-bottom: 8px;
}
.tag-list {
    margin-top: 20px;
}
form.set-tag-targets button[type=submit] {
    margin-top: 10px;
}
.tag-container {
    position: relative;
}
.entity-options {
    font-size: 1.25em;
}
.plot-type-tc .hidden-tc, .plot-type-drc .hidden-drc, .plot-type-drpar .hidden-drpar,
.plot-type-qc .hidden-qc {
    display: none;
}
.hts-plot-selection {
    display: none;
}
/** Tags in selected rows **/
.selected .label-primary {
    background-color: #022559;
}
.square-btns .btn-lg {
    width: 100%;
    margin-bottom: 4px;
    height: 125px;
    white-space: normal;
    min-width: 170px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
}
@media (min-width: 361px) {
    .square-btns .btn-lg {
        width: 49%;
        display: inline-block;
        max-width: 400px;
    }
}
@media (min-width: 768px) {
    .square-btns .btn-lg {
        width: 19%;
    }
}
.btn-two .square-btns .btn-lg {
    width: 49%;
    min-width: 107px;
    display: inline-block;
}
.square-btns.btn170 button, .square-btns.btn170 a.btn {
    width: 170px;
}
#dataset-table td {
    overflow: hidden;
    text-overflow: ellipsis;
}
.option-unavailable {
    color: #ccc !important;
}
.option-limited {
    color: #c9970b !important;
    font-style: italic;
}
